import React from "react";
import { graphql } from "gatsby";

import BlockContent from "components/BlockContent";
import Box from "components/Box";
import Heading from "components/Heading";
import Meta from "components/Meta";

const PostTemplate = ({ data, previewData }) => {
  const { main, meta } = previewData
    ? previewData.content
    : data.sanityPost._rawContent;

  return (
    <Box as="article" sx={{ maxWidth: "md", mx: "auto" }}>
      <Meta {...meta} />
      <Heading as="h1">{main.title}</Heading>
      <BlockContent blocks={main.body} />
    </Box>
  );
};

export default PostTemplate;

export const query = graphql`
  query sanityPost($id: String!) {
    sanityPost(id: { eq: $id }) {
      _rawContent(resolveReferences: { maxDepth: 9 })
    }
  }
`;
